var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "10" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "z-index": "9" },
                      attrs: {
                        color: "#003d6a",
                        dark: "",
                        dense: "",
                        elevation: "0",
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchBidCollapsed = !_vm.searchBidCollapsed
                        },
                      },
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("new_reservation"))),
                      ]),
                      _c("v-spacer"),
                      _c("div", { staticClass: "card-header-actions" }, [
                        _vm.searchBidCollapsed
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fas", "chevron-up"],
                                    size: "lg",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _vm._v(_vm._s(_vm.$t("search")) + " "),
                                _c("font-awesome-icon", {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: {
                                    icon: ["fas", "chevron-down"],
                                    size: "lg",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "CCollapse",
                    { attrs: { show: _vm.searchBidCollapsed } },
                    [
                      _c(
                        "CForm",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.searchBid.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.searchBidSelectLocationOptions,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t("pickup_location"),
                                      label: _vm.$t("pickup_location"),
                                      disabled: _vm.searchBidInputDisabled,
                                    },
                                    model: {
                                      value:
                                        _vm.searchBidSelectedDeliveryLocationSlug,
                                      callback: function ($$v) {
                                        _vm.searchBidSelectedDeliveryLocationSlug =
                                          $$v
                                      },
                                      expression:
                                        "searchBidSelectedDeliveryLocationSlug",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("VueCtkDateTimePicker", {
                                    attrs: {
                                      id: "pickup_datetime",
                                      "no-shortcuts": "",
                                      "no-header": "",
                                      format: "YYYY-MM-DD HH:mm",
                                      formatted: "Do MMM YYYY ddd HH:mm",
                                      minDate: _vm.deliveryMinDateTime,
                                      minuteInterval: _vm.offsetMinute,
                                      locale: _vm.locale,
                                      label: _vm.$t("pickup_time"),
                                    },
                                    model: {
                                      value:
                                        _vm.searchBidForm.delivery.datetime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchBidForm.delivery,
                                          "datetime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchBidForm.delivery.datetime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      label: _vm.$t("promo_code"),
                                      type: "text",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.promo_code =
                                          _vm.promo_code.turkishToUpper()
                                      },
                                    },
                                    model: {
                                      value: _vm.promo_code,
                                      callback: function ($$v) {
                                        _vm.promo_code = $$v
                                      },
                                      expression: "promo_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "5" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.searchBidSelectLocationOptions,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t("return_location"),
                                      label: _vm.$t("return_location"),
                                      disabled: _vm.searchBidInputDisabled,
                                    },
                                    model: {
                                      value:
                                        _vm.searchBidSelectedReturnLocationSlug,
                                      callback: function ($$v) {
                                        _vm.searchBidSelectedReturnLocationSlug =
                                          $$v
                                      },
                                      expression:
                                        "searchBidSelectedReturnLocationSlug",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("VueCtkDateTimePicker", {
                                    attrs: {
                                      id: "return_datetime",
                                      "no-shortcuts": "",
                                      "no-header": "",
                                      format: "YYYY-MM-DD HH:mm",
                                      formatted: "Do MMM YYYY ddd HH:mm",
                                      minDate: _vm.deliveryMinDateTime,
                                      minuteInterval: _vm.offsetMinute,
                                      locale: _vm.locale,
                                      label: _vm.$t("return_time"),
                                    },
                                    model: {
                                      value: _vm.searchBidForm.return.datetime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchBidForm.return,
                                          "datetime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchBidForm.return.datetime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", {
                                staticClass: "CheckContainer",
                                attrs: { cols: "2", md: "1" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.searchBidInputDisabled,
                                        type: "submit",
                                        block: "",
                                        color: "primary",
                                        loading: _vm.searchBidInputDisabled,
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("search")) + " "
                                        ),
                                      ]),
                                      _c(
                                        "v-icon",
                                        { attrs: { right: "", dark: "" } },
                                        [_vm._v(" mdi-magnify ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.bidDetailGridShow
                    ? _c(
                        "v-row",
                        { staticClass: "p-0" },
                        [
                          1 == 2
                            ? _c("v-btn", { on: { click: _vm.pdfExport } }, [
                                _vm._v("PDF Export"),
                              ])
                            : _vm._e(),
                          _c("ag-grid-vue", {
                            staticClass: "ag-theme-alpine",
                            staticStyle: {
                              width: "100%",
                              height: "calc(80vh)",
                            },
                            attrs: {
                              columnDefs: _vm.columnDefs,
                              defaultColDef: _vm.defaultColDef,
                              rowData: _vm.rowData,
                              sideBar: _vm.sideBar,
                              rowSelection: _vm.rowSelection,
                              enableRangeSelection: true,
                              enableBrowserTooltips: true,
                              enableRowGroup: true,
                              statusBar: _vm.statusBar,
                            },
                            on: {
                              rowDoubleClicked: _vm.onRowDoubleClicked,
                              "grid-ready": _vm.onGridReady,
                              "column-moved": _vm.onColumnMoved,
                              "column-pinned": _vm.onColumnMoved,
                              "column-visible": _vm.onColumnMoved,
                              "column-resized": _vm.onColumnMoved,
                              "column-changed": _vm.onColumnMoved,
                              "column-row-group-changed": _vm.onColumnMoved,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "z-index": "1031 !important" },
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
            persistent: "",
          },
          model: {
            value: _vm.newReservationModal,
            callback: function ($$v) {
              _vm.newReservationModal = $$v
            },
            expression: "newReservationModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", dense: "", elevation: "0" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.newReservationModal = false),
                            (_vm.additionalExtrasItems = [])
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "5", md: "6", sm: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-5", attrs: { elevation: "10" } },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                dense: "",
                                dark: "",
                                color: "grey darken-1",
                                elevation: "0",
                              },
                            },
                            [
                              _c("v-toolbar-title", [
                                _c("strong", [
                                  _vm._v(" " + _vm._s(_vm.$t("general")) + " "),
                                ]),
                              ]),
                              _c("v-spacer"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.newReservationModalForm.searchPrice.car
                                      .brand
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.newReservationModalForm.searchPrice.car
                                      .model
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-items-center justify-start",
                                  attrs: { cols: "12", md: "6", sm: "12" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      height: "70",
                                      contain: "",
                                      src:
                                        _vm.ENV_URL_CARS +
                                        _vm.newReservationModalForm.searchPrice
                                          .car.image,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "p-0 d-flex align-items-center",
                                  attrs: { cols: "12", md: "6", sm: "12" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src:
                                        _vm.ENV_URL_VENDOR +
                                        _vm.newReservationModalForm.searchPrice
                                          .vendor.logo,
                                      "max-width": "150",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-5",
                                  attrs: { sm: "12", md: "12" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "align-items-center justify-content-center",
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("class")) +
                                                " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.newReservationModalForm
                                                      .searchPrice.car.class
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("gear"))),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.newReservationModalForm
                                                      .searchPrice.car
                                                      .transmission
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("fuel"))),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.newReservationModalForm
                                                      .searchPrice.car.fuel
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("seat"))),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.car.seat
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("km_limit"))),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.rules
                                                    .dailyRangeLimit === 0
                                                    ? _vm.$t("unlimited")
                                                    : _vm
                                                        .newReservationModalForm
                                                        .searchPrice.rules
                                                        .totalRangeLimit + " KM"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("age")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " + " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.rules.driverAge
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$t("driving_license"))
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " Min. " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.rules
                                                    .licenseYears
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("yearly")) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("fuel_policy")) +
                                                " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.newReservationModalForm
                                                      .searchPrice.car
                                                      .fuelPolicy
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "m-0" }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { col: "10" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { col: "4" } }, [
                                        _c("h6", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("pickup")) + " "
                                          ),
                                        ]),
                                        _c(
                                          "h5",
                                          {
                                            staticStyle: {
                                              "font-weight": "900",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .location.start.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center mt-3",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: ["fas", "calendar-alt"],
                                                size: "lg",
                                              },
                                            }),
                                            _c("p", { staticClass: "h6 m-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm
                                                        .newReservationModalForm
                                                        .datetime.start,
                                                      "Do MMMM YYYY, dddd"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm
                                                        .newReservationModalForm
                                                        .datetime.start,
                                                      "HH:mm"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center mt-3",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: ["fas", "info-circle"],
                                                size: "lg",
                                              },
                                            }),
                                            _c("p", { staticClass: "h6 m-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$store.state.ceviri[
                                                      _vm
                                                        .newReservationModalForm
                                                        .searchPrice.office
                                                        .deliveryType
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "m-0 mt-1 small" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.office.address
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("v-divider", {
                                        attrs: { vertical: "" },
                                      }),
                                      _c("v-col", { attrs: { col: "6" } }, [
                                        _c("h6", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("return")) + " "
                                          ),
                                        ]),
                                        _c(
                                          "h5",
                                          {
                                            staticStyle: {
                                              "font-weight": "900",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .location.end.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center mt-3",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: ["fas", "calendar-alt"],
                                                size: "lg",
                                              },
                                            }),
                                            _c("p", { staticClass: "h6 m-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm
                                                        .newReservationModalForm
                                                        .datetime.end,
                                                      "Do MMMM YYYY, dddd"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm
                                                        .newReservationModalForm
                                                        .datetime.end,
                                                      "HH:mm"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center mt-3",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: ["fas", "info-circle"],
                                                size: "lg",
                                              },
                                            }),
                                            _c("p", { staticClass: "h6 m-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$store.state.ceviri[
                                                      _vm
                                                        .newReservationModalForm
                                                        .searchPrice
                                                        .returnOffice
                                                        .deliveryType
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "m-0 mt-1 small" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newReservationModalForm
                                                    .searchPrice.returnOffice
                                                    .address
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "m-0" }),
                          !_vm.additionalExtrasItems
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("p", { staticClass: "text-center" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "no_additional_products_found"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.additionalExtrasItems?.length > 0
                            ? _c(
                                "v-row",
                                { staticClass: "p-1" },
                                _vm._l(
                                  _vm.additionalExtrasItems,
                                  function (extra, i) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: i,
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between align-items-center",
                                          },
                                          [
                                            _c("div", [
                                              _c("p", { staticClass: "m-0" }, [
                                                _c("strong", [
                                                  _vm._v(_vm._s(extra.name)),
                                                ]),
                                              ]),
                                              _c("p", { staticClass: "m-0" }, [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      extra.totalPrice[
                                                        _vm.$store.state
                                                          .currency
                                                      ].toLocaleString(
                                                        "tr-TR",
                                                        {
                                                          style: "currency",
                                                          currency:
                                                            _vm.$store.state
                                                              .currency,
                                                        }
                                                      ) || null
                                                    ) + " "
                                                  ),
                                                ]),
                                                extra.extradaily == 1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            extra.dailyPrice[
                                                              _vm.$store.state
                                                                .currency
                                                            ].toLocaleString(
                                                              "tr-TR",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency:
                                                                  _vm.$store
                                                                    .state
                                                                    .currency,
                                                              }
                                                            ) || null
                                                          ) +
                                                          " x " +
                                                          _vm._s(
                                                            `${(
                                                              extra.totalPrice[
                                                                _vm.$store.state
                                                                  .currency
                                                              ] /
                                                              extra.dailyPrice[
                                                                _vm.$store.state
                                                                  .currency
                                                              ]
                                                            ).toFixed(0)}`
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t("day")
                                                          ) +
                                                          ") "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("per_rental")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]),
                                            ]),
                                            _c(
                                              "div",
                                              [
                                                extra.status
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setExtra(
                                                              extra.name,
                                                              extra.dailyPrice,
                                                              extra.totalPrice,
                                                              0,
                                                              extra.vendor,
                                                              extra.code
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "fas",
                                                                "minus",
                                                              ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !extra.status
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setExtra(
                                                              extra.name,
                                                              extra.dailyPrice,
                                                              extra.totalPrice,
                                                              1,
                                                              extra.vendor,
                                                              extra.code
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "fas",
                                                                "plus",
                                                              ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { color: "gray" } },
                                          [
                                            _c("small", [
                                              _vm._v(_vm._s(extra.description)),
                                            ]),
                                          ]
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mb-0",
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm.additionalExtrasItems?.length <= 0 &&
                          _vm.additionalExtrasItems
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "p",
                                      { staticClass: "text-center" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "primary",
                                            size: "20",
                                          },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("loading")) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "12", lg: "7", md: "6", sm: "12" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "7",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "10" } },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        dense: "",
                                        dark: "",
                                        color: "grey darken-1",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c("v-toolbar-title", [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("driver_info")) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "select-user-box noselect",
                                                },
                                                [
                                                  Object.keys(
                                                    _vm.usersModalSelectedUser
                                                  ).length === 0
                                                    ? _c("CInput", {
                                                        staticClass:
                                                          "content-fix m-0",
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "search_for_registered_driver"
                                                          ),
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "append-content",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "input-group-btn-right btn-bg-white",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.searchUser()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "search_user"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          446780882
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.usersModalForm
                                                              .searchValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.usersModalForm,
                                                              "searchValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "usersModalForm.searchValue",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  Object.keys(
                                                    _vm.usersModalSelectedUser
                                                  ).length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "selected-user",
                                                        },
                                                        [
                                                          _c("label", {
                                                            staticClass: "m-0",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm
                                                                  .usersModalSelectedUser
                                                                  .name +
                                                                  " " +
                                                                  _vm
                                                                    .usersModalSelectedUser
                                                                    .lastname
                                                              ),
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "btn-right",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unselectUser()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "remove"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "validation-observer",
                                        { ref: "observer" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t("name"),
                                                        rules:
                                                          "required|alpha_spaces|max:500",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label: _vm.$t("name"),
                                                          required: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.newReservationModalForm.driverInfo.name =
                                                              _vm.newReservationModalForm.driverInfo.name.turkishToUpper()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo.name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.name\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { col: "6" } },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "lastname"
                                                        ),
                                                        rules:
                                                          "required|alpha_spaces|max:500",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label:
                                                            _vm.$t("lastname"),
                                                          required: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.newReservationModalForm.driverInfo.lastname =
                                                              _vm.newReservationModalForm.driverInfo.lastname.turkishToUpper()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo
                                                              .lastname,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo,
                                                              "lastname",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.lastname\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "mobile_phone"
                                                        ),
                                                        rules: {
                                                          required: true,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("vue-tel-input", {
                                                        attrs: {
                                                          inputOptions: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "mobile_phone"
                                                              ),
                                                          },
                                                          defaultCountry: "tr",
                                                          mode: "international",
                                                        },
                                                        on: {
                                                          input: _vm.onInput,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo
                                                              .phoneInput,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo,
                                                              "phoneInput",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.phoneInput\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        name: _vm.$t("email"),
                                                        mode: "eager",
                                                        rules: "required|email",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label:
                                                            _vm.$t("email"),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo.email,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo,
                                                              "email",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.email\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("label", {
                                                    staticClass:
                                                      "font-weight-bold",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$t("date_of_birth")
                                                      ),
                                                    },
                                                  }),
                                                  _c("imask-input", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      mask: Date,
                                                      lazy: false,
                                                      radix: ".",
                                                      unmask: false,
                                                      placeholder:
                                                        _vm.$t("date_of_birth"),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .newReservationModalForm
                                                          .driverInfo.birthday,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .newReservationModalForm
                                                            .driverInfo,
                                                          "birthday",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                            newReservationModalForm.driverInfo.birthday\n                          ",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("CInputRadioGroup", {
                                                    staticClass:
                                                      "mb-2 font-weight-bold cursor-pointer noselect",
                                                    attrs: {
                                                      options: [
                                                        {
                                                          label:
                                                            _vm.$t(
                                                              "tc_identity"
                                                            ),
                                                          value: "tc",
                                                        },
                                                        {
                                                          label:
                                                            _vm.$t("passport"),
                                                          value: "passport",
                                                        },
                                                      ],
                                                      checked:
                                                        _vm
                                                          .newReservationModalForm
                                                          .driverInfo.identity
                                                          .certificateType,
                                                      inline: true,
                                                    },
                                                    on: {
                                                      "update:checked":
                                                        _vm.driverIdentityChanged,
                                                    },
                                                  }),
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "tc_identity"
                                                        ),
                                                        rules:
                                                          _vm
                                                            .newReservationModalForm
                                                            .driverInfo.identity
                                                            .certificateType ==
                                                          "tc"
                                                            ? "required|length:11"
                                                            : "",
                                                      },
                                                    },
                                                    [
                                                      _c("imask-input", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          mask:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo
                                                              .identity
                                                              .certificateType ==
                                                            "tc"
                                                              ? /^\d{0,11}$/
                                                              : "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo
                                                              .identity.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo
                                                                .identity,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.identity\n                                .value\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "m-0",
                                          }),
                                          !_vm.isPartner
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "color-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "billing_information"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isPartner
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "bil__type",
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label:
                                                            _vm.$t(
                                                              "billing_type"
                                                            ),
                                                          items: _vm.bilType,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .invoices
                                                              .invoiceType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .invoices,
                                                              "invoiceType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                            newReservationModalForm.invoices.invoiceType\n                          ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.newReservationModalForm
                                                    .invoices.invoiceType ==
                                                  "individual"
                                                    ? _c(
                                                        "v-row",
                                                        [
                                                          !_vm.dataDropDown
                                                            .cities.length
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "province"
                                                                        ),
                                                                        rules:
                                                                          "required|alpha_spaces|max:500",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "province"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individual
                                                                                  .province,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individual,
                                                                                    "province",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                newReservationModalForm.invoices.individual\n                                  .province\n                              ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    mode: "eager",
                                                                    name: _vm.$t(
                                                                      "post_code"
                                                                    ),
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        label:
                                                                          _vm.$t(
                                                                            "post_code"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .newReservationModalForm
                                                                            .invoices
                                                                            .individual
                                                                            .postCode,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .newReservationModalForm
                                                                                .invoices
                                                                                .individual,
                                                                              "postCode",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                newReservationModalForm.invoices.individual\n                                  .postCode\n                              ",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                lg: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    mode: "eager",
                                                                    name: _vm.$t(
                                                                      "address"
                                                                    ),
                                                                    rules:
                                                                      "required|max:500",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        label:
                                                                          _vm.$t(
                                                                            "address"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .newReservationModalForm
                                                                            .invoices
                                                                            .individual
                                                                            .address,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .newReservationModalForm
                                                                                .invoices
                                                                                .individual,
                                                                              "address",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                newReservationModalForm.invoices.individual\n                                  .address\n                              ",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm
                                                        .newReservationModalForm
                                                        .invoices.invoiceType ==
                                                      "individualCompany"
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "name"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "name"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .firstname,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "firstname",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.firstname\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "lastname"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "lastname"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .lastname,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "lastname",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.lastname\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "tax_office"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "tax_office"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .taxOffice,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "taxOffice",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.taxOffice\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "tax_tc"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "tax_tc"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .taxNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "taxNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.taxNumber\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "province"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "province"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .province,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "province",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.province\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "post_code"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "post_code"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .postCode,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "postCode",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.postCode\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    lg: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "address"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "address"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .individualCompany
                                                                                  .address,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .individualCompany,
                                                                                    "address",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices\n                                    .individualCompany.address\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm
                                                        .newReservationModalForm
                                                        .invoices.invoiceType ==
                                                      "company"
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "company_title"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "company_title"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .title,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "title",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .title\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "tax_office"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "tax_office"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .taxOffice,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "taxOffice",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .taxOffice\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "tax_number"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "mask",
                                                                                rawName:
                                                                                  "v-mask",
                                                                                value:
                                                                                  "###########",
                                                                                expression:
                                                                                  "'###########'",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              min: "10",
                                                                              max: "11",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "tax_number"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .taxNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "taxNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .taxNumber\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "province"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "province"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .province,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "province",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .province\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "post_code"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "post_code"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .postCode,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "postCode",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .postCode\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    lg: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "address"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "address"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .address,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "address",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .address\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm
                                                        .newReservationModalForm
                                                        .invoices.invoiceType ==
                                                      "abroad"
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "company_title"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "company_title"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .abroad
                                                                                  .title,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .abroad,
                                                                                    "title",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.abroad\n                                    .title\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "name"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "name"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .abroad
                                                                                  .firstname,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .abroad,
                                                                                    "firstname",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.abroad\n                                    .firstname\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "lastname"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "lastname"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .abroad
                                                                                  .lastname,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .abroad,
                                                                                    "lastname",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.abroad\n                                    .lastname\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name:
                                                                          _vm.$t(
                                                                            "passport"
                                                                          ) +
                                                                          "No",
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "passport"
                                                                                ) +
                                                                                "No",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .abroad
                                                                                  .passportNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .abroad,
                                                                                    "passportNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.abroad\n                                    .passportNumber\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "tax_number"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "tax_number"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .taxNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "taxNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .taxNumber\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-col", [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          reduce:
                                                                            (
                                                                              item
                                                                            ) =>
                                                                              item,
                                                                          items:
                                                                            _vm
                                                                              .dummy
                                                                              .countries,
                                                                          "item-value":
                                                                            "value",
                                                                          "item-text":
                                                                            "name",
                                                                          label:
                                                                            _vm.$t(
                                                                              "country"
                                                                            ),
                                                                          placeholder:
                                                                            _vm.$t(
                                                                              "select_country"
                                                                            ),
                                                                          id: "id",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .newReservationModalForm
                                                                              .country,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.newReservationModalForm,
                                                                                "country",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "newReservationModalForm.country",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "province"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "province"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .province,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "province",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .province\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "post_code"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "post_code"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .postCode,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "postCode",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .postCode\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    lg: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        mode: "eager",
                                                                        name: _vm.$t(
                                                                          "address"
                                                                        ),
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs:
                                                                            {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              label:
                                                                                _vm.$t(
                                                                                  "address"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newReservationModalForm
                                                                                  .invoices
                                                                                  .company
                                                                                  .address,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .newReservationModalForm
                                                                                      .invoices
                                                                                      .company,
                                                                                    "address",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                  newReservationModalForm.invoices.company\n                                    .address\n                                ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.userPermissionModal
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    _vm._l(
                                                      _vm.permissions,
                                                      function (
                                                        option,
                                                        optKey
                                                      ) {
                                                        return _c(
                                                          "CInputCheckbox",
                                                          {
                                                            key:
                                                              option + optKey,
                                                            attrs: {
                                                              inline: true,
                                                              label:
                                                                option.label,
                                                              value:
                                                                option.value,
                                                              checked:
                                                                _vm.newReservationModalForm.driverInfo.permissions.some(
                                                                  (item) =>
                                                                    item ===
                                                                    option.value
                                                                ),
                                                            },
                                                            on: {
                                                              "update:checked":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.updatePermissionsCheckBox(
                                                                    option.value
                                                                  )
                                                                },
                                                            },
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "flight_number"
                                                        ),
                                                        rules:
                                                          _vm.boardingPassRequired
                                                            ? "required"
                                                            : "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label:
                                                            _vm.$t(
                                                              "flight_number"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .driverInfo
                                                              .flightNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .newReservationModalForm
                                                                .driverInfo,
                                                              "flightNo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              newReservationModalForm.driverInfo.flightNo\n                            ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            staticClass: "my-0",
                                          }),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass: "color-red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "generalDescription"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "vendorDescription"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          outlined: "",
                                                          rows: "2",
                                                          dense: "",
                                                          "hide-details": "",
                                                          label:
                                                            _vm.$t(
                                                              "vendorDescription"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .description,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newReservationModalForm,
                                                              "description",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newReservationModalForm.description",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "5",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "10" } },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        dense: "",
                                        dark: "",
                                        color: "grey darken-1",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c("v-toolbar-title", [
                                        _c("strong", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("payment"))
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _vm.newReservationModalForm
                                            .searchPrice.vendor.isFullCredit ||
                                          1 == 1
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          name: "payment-type",
                                                          items:
                                                            _vm.reservationTypes,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          label:
                                                            _vm.$t(
                                                              "reservation_type"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .newReservationModalForm
                                                              .reservationType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newReservationModalForm,
                                                              "reservationType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newReservationModalForm.reservationType",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        mode: "eager",
                                                        name: _vm.$t(
                                                          "payment_type"
                                                        ),
                                                        rules: "required",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          name: "payment-type",
                                                          items:
                                                            _vm.typesOfPaymentAvailableOptions,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          label:
                                                            _vm.$t(
                                                              "payment_type"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.paymentType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.paymentType =
                                                              $$v
                                                          },
                                                          expression:
                                                            "paymentType",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.calculatedPrices?.totalRentalPrice
                                            ?.TRY
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "rental_period"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .newReservationModalForm
                                                                  .searchPrice
                                                                  .totalDays
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t("day")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "rental_price"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("small", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("daily")
                                                              )
                                                            ),
                                                          ]),
                                                          _vm
                                                            .newReservationModalForm
                                                            .searchPrice.vendor
                                                            .overSelling
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.editingDailyPrice =
                                                                          !_vm.editingDailyPrice
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "small",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-pencil"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "change"
                                                                            )
                                                                          )
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      !_vm.editingDailyPrice
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-4 text-right font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.func
                                                                    .toMoneyFormat(
                                                                      _vm
                                                                        .calculatedPrices
                                                                        ?.dailyRentalPrice[
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency
                                                                      ],
                                                                      true
                                                                    )
                                                                    .toLocaleString(
                                                                      "tr-TR",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          _vm
                                                                            .$store
                                                                            .state
                                                                            .currency,
                                                                      }
                                                                    ) || null
                                                                ) + " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.editingDailyPrice
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-4 text-right font-weight-bold",
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    "hide-details":
                                                                      "",
                                                                    dense: "",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.setLoadingPrice,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .calculatedPrices
                                                                        .dailyRentalPrice[
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .calculatedPrices
                                                                            .dailyRentalPrice,
                                                                          _vm
                                                                            .$store
                                                                            .state
                                                                            .currency,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "\n                              calculatedPrices.dailyRentalPrice[\n                                $store.state.currency\n                              ]\n                            ",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "rental_price"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("small", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("total")
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.func
                                                                  .toMoneyFormat(
                                                                    _vm
                                                                      .calculatedPrices
                                                                      ?.totalRentalPrice[
                                                                      _vm.$store
                                                                        .state
                                                                        .currency
                                                                    ],
                                                                    true
                                                                  )
                                                                  .toLocaleString(
                                                                    "tr-TR",
                                                                    {
                                                                      style:
                                                                        "currency",
                                                                      currency:
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency,
                                                                    }
                                                                  ) || null
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.calculatedPrices
                                                    .selectedExtras > 0
                                                    ? _c("v-divider", {
                                                        staticClass: "m-0",
                                                      })
                                                    : _vm._e(),
                                                  _vm._l(
                                                    _vm.calculatedPrices
                                                      ?.selectedExtras,
                                                    function (data, key) {
                                                      return _c(
                                                        "v-row",
                                                        {
                                                          key: key,
                                                          staticClass: "mb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              class:
                                                                "col-8" +
                                                                (key % 2
                                                                  ? " bg-grey"
                                                                  : ""),
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    icon: [
                                                                      "fas",
                                                                      "plus",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              class:
                                                                "col-4 text-right font-weight-bold" +
                                                                (key % 2
                                                                  ? " bg-grey"
                                                                  : ""),
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.func
                                                                    .toMoneyFormat(
                                                                      data
                                                                        .totalPrice[
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency
                                                                      ]
                                                                    )
                                                                    .toLocaleString(
                                                                      "tr-TR",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          _vm
                                                                            .$store
                                                                            .state
                                                                            .currency,
                                                                      }
                                                                    ) || null
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _vm.calculatedPrices
                                                    ?.totalExtraPrice[
                                                    _vm.$store.state.currency
                                                  ] > 0
                                                    ? _c(
                                                        "v-row",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-8 font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "total_extras"
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-4 text-right font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.func
                                                                    .toMoneyFormat(
                                                                      _vm
                                                                        .calculatedPrices
                                                                        ?.totalExtraPrice[
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency
                                                                      ]
                                                                    )
                                                                    .toLocaleString(
                                                                      "tr-TR",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          _vm
                                                                            .$store
                                                                            .state
                                                                            .currency,
                                                                      }
                                                                    ) || null
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.calculatedPrices
                                                    ?.dropPrice[
                                                    _vm.$store.state.currency
                                                  ] > 0
                                                    ? _c(
                                                        "v-row",
                                                        { staticClass: "mb-3" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-8 font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "drop_off_price"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-4 text-right font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.func
                                                                      .toMoneyFormat(
                                                                        _vm
                                                                          .calculatedPrices
                                                                          ?.dropPrice[
                                                                          _vm
                                                                            .$store
                                                                            .state
                                                                            .currency
                                                                        ]
                                                                      )
                                                                      .toLocaleString(
                                                                        "tr-TR",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            _vm
                                                                              .$store
                                                                              .state
                                                                              .currency,
                                                                        }
                                                                      )
                                                                  ) +
                                                                  " ₺ "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "now_payment_price"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.func
                                                                  .toMoneyFormat(
                                                                    _vm
                                                                      .calculatedPrices
                                                                      ?.payNow[
                                                                      _vm.$store
                                                                        .state
                                                                        .currency
                                                                    ]
                                                                  )
                                                                  .toLocaleString(
                                                                    "tr-TR",
                                                                    {
                                                                      style:
                                                                        "currency",
                                                                      currency:
                                                                        _vm
                                                                          .$store
                                                                          .state
                                                                          .currency,
                                                                    }
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "delivery_payment_price"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calculatedPrices?.payLater[
                                                                  _vm.$store
                                                                    .state
                                                                    .currency
                                                                ].toLocaleString(
                                                                  "tr-TR",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      _vm.$store
                                                                        .state
                                                                        .currency,
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "total_payment_price2"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _vm
                                                            .newReservationModalForm
                                                            .reservationType !=
                                                          "fullCredit"
                                                            ? _c("small", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "excluding_deposit"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calculatedPrices?.totalPrice[
                                                                  _vm.$store
                                                                    .state
                                                                    .currency
                                                                ].toLocaleString(
                                                                  "tr-TR",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      _vm.$store
                                                                        .state
                                                                        .currency,
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    staticClass: "m-0",
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "col-8",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "deposit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-4 text-right font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calculatedPrices?.provision[
                                                                  _vm.$store
                                                                    .state
                                                                    .currency
                                                                ]?.toLocaleString(
                                                                  "tr-TR",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      _vm.$store
                                                                        .state
                                                                        .currency,
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "justify-end mt-3" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error" },
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.newReservationModal = false),
                                            (_vm.additionalExtrasItems = [])
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("cancel")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.createReservationBtnStatus,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createReservation()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.createReservationBtnStatus,
                                            expression:
                                              "createReservationBtnStatus",
                                          },
                                        ],
                                        staticClass: "spin mr-2",
                                        attrs: {
                                          icon: ["fas", "circle-notch"],
                                          size: "lg",
                                        },
                                      }),
                                      _vm.paymentType == "noneSecurePos"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("continue")) +
                                                " "
                                            ),
                                          ])
                                        : _vm.paymentType == "smsPos"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("continue")) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "complete_the_reservation"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.usersModal,
            callback: function ($$v) {
              _vm.usersModal = $$v
            },
            expression: "usersModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.usersModalTitle)),
                ]),
              ]),
              _c("ag-grid-vue", {
                staticClass: "ag-theme-balham",
                staticStyle: { width: "100%", height: "450px" },
                attrs: {
                  columnDefs: _vm.usersModalColumnDefs,
                  defaultColDef: _vm.usersModalDefaultColDef,
                  rowData: _vm.usersModalRowData,
                  getContextMenuItems: _vm.getUserModalGridContextMenuItems,
                  statusBar: _vm.usersModalStatusBar,
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  rowDoubleClicked: _vm.onRowDoubleClickedUserModal,
                },
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.usersModal = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.driversModal,
            callback: function ($$v) {
              _vm.driversModal = $$v
            },
            expression: "driversModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.driversModalTitle)),
                ]),
              ]),
              _c("ag-grid-vue", {
                staticClass: "ag-theme-balham",
                staticStyle: { width: "100%", height: "500px" },
                attrs: {
                  columnDefs: _vm.driversModalColumnDefs,
                  defaultColDef: _vm.driversModalDefaultColDef,
                  rowData: _vm.driversModalRowData,
                  getContextMenuItems: _vm.getDriverModalGridContextMenuItems,
                  statusBar: _vm.driversModalStatusBar,
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  rowDoubleClicked: _vm.onRowDoubleClickedDriverModal,
                },
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.driversModal = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "290" },
              model: {
                value: _vm.manipulateInfoModal,
                callback: function ($$v) {
                  _vm.manipulateInfoModal = $$v
                },
                expression: "manipulateInfoModal",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" " + _vm._s(_vm.$t("warning")) + "! "),
                  ]),
                  _c("v-card-text", [_vm._v(_vm._s(_vm.$t("warnin_info")))]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.manipulateInfoModal = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickAggreInfoManipulate()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("accept")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }